import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const PAGE_TITLE = "Water, Satination and Hygene (WASH & PCI)"
const PAGE_NAME = "wash_pci"
const WaterSanitationPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
        
		<div className="row">
          <div className="col-sm-12">

			<h1>
				PAGE UNDER CONSTRUCTION
			</h1>
			
			
			
          </div>
        </div>
        
		

		
      </section>
    </Layout>
  )
}

export default WaterSanitationPage
